<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>{{ $t('tracker.subtitle') }}</v-card-title>
      <v-card-text>
        <v-stepper v-model="step" vertical>
          <v-stepper-step :complete="step > 1" step="1" class="pt-2 pb-0">
            {{ $t('tracker.step.receiving.title') }}
          </v-stepper-step>
          <v-stepper-content step="1" class="ma-0 pa-0" outlined>
            <v-card color="grey lighten-1" class="mb-6">
              <v-card-text class="ma-0 pa-2 text-center">
                <p v-for="content in $t('tracker.step.receiving.content')" :key="content">
                  {{ content }}
                </p>
                <v-progress-circular :rotate="-90" :size="80" :width="10" indeterminate color="primary" dense />
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-step v-if="step >= 2" :complete="step > 2" step="2" class="pt-2 pb-0">
            {{ $t('tracker.step.created.title') }}
          </v-stepper-step>
          <v-stepper-content v-if="step >= 2" step="2" class="ma-0 pa-0">
            <v-card color="grey lighten-1" class="mb-6">
              <v-card-text class="ma-0 pa-2 text-center">
                <p v-for="content in $t('tracker.step.created.content')" :key="content">
                  {{ content }}
                </p>
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-step v-if="step >= 3" :complete="step > 3" step="3" class="pt-2 pb-0">
            {{ $t('tracker.step.unpaid.title') }}
          </v-stepper-step>
          <v-stepper-content v-if="step >= 3" step="3" class="ma-0 pa-0">
            <v-card color="grey lighten-1" class="mb-6">
              <v-card-text class="ma-0 pa-2">
                <payment />
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-step v-if="step >= 4" :complete="step > 4" step="4" class="pt-2 pb-0">
            {{ $t('tracker.step.paid.title') }}
          </v-stepper-step>
          <v-stepper-content v-if="step >= 4" step="4" class="ma-0 pa-0">
            <v-card color="grey lighten-1" class="mb-6">
              <v-card-text class="ma-0 pa-2 text-center">
                <p v-for="content in $t('tracker.step.paid.content')" :key="content">
                  {{ content }}
                </p>
                <v-progress-circular :rotate="-90" :size="80" :width="10" indeterminate color="primary" dense />
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-step v-if="step >= 5" :complete="step > 5" step="5" class="pt-2 pb-0">
            {{ $t('tracker.step.preparation.title') }}
          </v-stepper-step>
          <v-stepper-content v-if="step >= 5" step="5" class="ma-0 pa-0">
            <v-card color="grey lighten-1" class="mb-6">
              <v-card-text class="ma-0 pa-2 text-center">
                <p v-for="content in $t('tracker.step.preparation.content')" :key="content">
                  {{ content }}
                </p>
                <spinner-cooking />
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-step v-if="step >= 6" :complete="step > 6" step="6" class="pt-2 pb-0">
            {{ $t('tracker.step.delivery.title') }}
          </v-stepper-step>
          <v-stepper-content v-if="step >= 6" step="6" class="ma-0 pa-0">
            <v-card color="grey lighten-1" class="mb-6">
              <v-card-text class="ma-0 pa-2 text-center">
                <p v-for="content in $t('tracker.step.delivery.content')" :key="content">
                  {{ content }}
                </p>
                <spinner-delivery />
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-step v-if="step >= 7" :complete="step > 7" step="7" class="pt-2 pb-0">
            {{ $t('tracker.step.delivered.title') }}
          </v-stepper-step>
          <v-stepper-content v-if="step >= 7" step="7" class="ma-0 pa-0">
            <v-card color="grey lighten-1" class="mb-6">
              <v-card-text class="ma-0 pa-2 text-center">
                <p v-for="content in $t('tracker.step.delivered.content')" :key="content">
                  {{ content }}
                </p>
                <spinner-wine />
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import SpinnerDelivery from '@/components/spinner/SpinnerDelivery';
  import SpinnerCooking from '@/components/spinner/SpinnerCooking';
  import SpinnerWine from '@/components/spinner/SpinnerWine';
  import Payment from '@/views/Payment';

  export default {
    components: { SpinnerCooking, Payment, SpinnerDelivery, SpinnerWine },
    data () {
      return {
        step: 1
      };
    }
  };
</script>
<style lang="scss" scoped>
  .v-stepper {
    box-shadow: none !important;
  }
  .v-card {
    box-shadow: none !important;
  }
</style>
