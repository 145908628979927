<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>{{ $t('payment.subtitle') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-radio-group
                    v-model="paymentMethod"
                    row
                  >
                    <v-radio value="credit-debit-card">
                      <template #label>
                        <span class="text--primary text-base me-1">Credit/Debit/ATM Card</span>
                        <v-icon size="18">
                          {{ icons.mdiCreditCardOutline }}
                        </v-icon>
                      </template>
                    </v-radio>
                    <v-radio value="cash-on-delivery">
                      <template #label>
                        <span class="text--primary text-base me-1">Cash On Delivery</span>
                        <v-icon size="18">
                          {{ icons.mdiHelpCircleOutline }}
                        </v-icon>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Card Number"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Name"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-text-field
                    label="Expiry Date"
                    dense
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-text-field
                    label="CVV Code"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-divider class="my-5" />
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-5"
            >
              Place Order
            </v-btn>
            <v-btn
              outlined
              color="secondary"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import { mdiCreditCardOutline, mdiHelpCircleOutline } from '@mdi/js';

  export default {
    setup () {
      return {
        icons: {
          mdiCreditCardOutline,
          mdiHelpCircleOutline
        }
      };
    }
  };
</script>
